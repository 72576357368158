import { NAV_ROUTES } from "@/constant/navRoutesConstant";
import { useRouter } from "next/router";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { RootState } from "redux/storeSchema";

const Index = () => {
  const { auth } = useSelector((state: RootState) => state.auth);
  const router = useRouter();

  useEffect(() => {
    if (auth?.isValid) {
      router.push(NAV_ROUTES.LA_CALENDAR);
    } else {
      router.push(NAV_ROUTES.USER_DETAILS_FORM);
    }
  }, []);
  return <div className="bg-white">Leap Advantage home page </div>;
};

export default Index;
